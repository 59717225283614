import * as React from "react"
import ContactForm from "../components/global/contact-form/ContactForm"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Hosting = () => (
  <Layout>
    <Seo title="Hosting NSC" />
    <div className="card card--center-text">
      <h1 className="text-red">Hosting</h1>
      <p className="center-text">
        I offer fast, secure and reliable managed web hosting. My hosting servers run
        on the fastest coding and architecture around. Your website will be in
        safe hands and all my services are scalable meaning you can grow your
        site based on your needs and budget.
      </p>
    </div>
    <div className="card card--center-text">
      <h2>Fast</h2>
      <p>
        Run on a Linux server with the latest PHP version with ultra fast
        settings.
      </p>

      <h2>Storage</h2>
      <p>Starting at 2GB for my basic package, increase as you grow.</p>

      <h2>Daily Backups</h2>
      <p>
        Your site is backed up daily and can be restored up to 3 days prior upon
        request.
      </p>

      <h2>Free SSL</h2>
      <p>
        SSL is a must for security to prevent hackers cloning your site. I
        offer this as a free service for your peace of mind.
      </p>

      <h2>Managed Hosting</h2>
      <p>
        If you have any issues with your hosting I can sort them for you. CMS
        websites require constant updating and sometimes this can break your
        website. I can manage this for you.
      </p>
    </div>
    <ContactForm />
  </Layout>
)

export default Hosting
